
import { useState } from "react";
import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";

export default function ContactPage() {
    const [errorMessage, setErrorMessage] = useState('');
    const [aletErrorVisible, setAletErrorVisible] = useState(false);
    const [aletSuccessVisible, setAletSuccessVisible] = useState(false);
    const [loading, SetLoding] = useState(false);
    const [name, SetName] = useState('');
    const [mobile, SetMobile] = useState('');
    const [email, SetEmail] = useState('');
    const [subject, SetSubject] = useState('');
    const [message, SetMessage] = useState('');
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setAletSuccessVisible(false);
        setAletErrorVisible(false);
        if (name === '') {
            setAletErrorVisible(true);
            setErrorMessage("Name is required");
            window.scrollTo({ top: 200, behavior: 'smooth' })
        } else if (mobile === '') {
            setAletErrorVisible(true);
            setErrorMessage("Mobile is required");
            window.scrollTo({ top: 200, behavior: 'smooth' })
        } else if (email === '') {
            setAletErrorVisible(true);
            setErrorMessage("Email is required");
            window.scrollTo({ top: 200, behavior: 'smooth' })
        } else if (subject === '') {
            setAletErrorVisible(true);
            setErrorMessage("Subject is required");
            window.scrollTo({ top: 200, behavior: 'smooth' })
        } else if (message === '') {
            setAletErrorVisible(true);
            setErrorMessage("Message is required");
            window.scrollTo({ top: 200, behavior: 'smooth' })
        } else {
            SetLoding(true);

            fetch("http://freshleadstech.com/sendemail.php", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: name, email: email, mobile: mobile, subject: subject, message: message })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        SetLoding(false);
                        setAletSuccessVisible(true);
                        setErrorMessage(result);
                        SetName('');
                        SetEmail('');
                        SetMobile('');
                        SetMessage('');
                        SetSubject('');
                    },                   
                    (error) => {
                        SetLoding(false);
                        setErrorMessage(error);
                    }
                )
        }

    }
    return (<>
        <MobileMenu isActive={"contact"} />
        <div className="pr__wrapper" id="site-wrapper" style={{ backgroundColor: '#fafafa' }}>
            <div className="pr__hero__wrap pr__hero__wrap_contact" id="site-hero">
                <WebHeader isActive={"contact"} />
                <section className="pr__hero uk-section" id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="hero-content uk-grid" data-uk-grid="">
                                <div className="uk-width-2-3@s">
                                    <hr className="line pr__hr__primary" />
                                    <h2 className="title uk-heading-hero">Let's Talk?.</h2>
                                    <span className="subtitle pr__heading__secondary">Let’s make something awesome togheter</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pr__hero_image_section " id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="image pr__image__cover" style={{ backgroundSize: 'contain', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/contact_us.png" data-uk-img=""></div>
                        </div>
                    </div>
                </section>
                <section className="pr__features uk-section  uk-section-large uk-padding-remove-bottom" id="pr_features">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                data-uk-grid="">
                                <div className="item  ">
                                    <div>&nbsp;</div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="pr__content" id="site-content">
                <section className="pr__about pr__section" id="pr__about">
                    <div className="section-outer">
                        <div className="section-inner">
                            <div className="uk-container">
                                <div className="gallery-boxes pr__grd__overlay">

                                    <form className=" pr__form" onSubmit={handleSubmit} >
                                        {aletErrorVisible && <div className="uk-alert-danger uk-padding" uk-alert>
                                            <p>{errorMessage}</p>
                                        </div>}
                                        <div className="pr__form__group">
                                            <label htmlFor="name">Name <span className="required">*</span></label>
                                            <input className="pr-input" id="name" name="name" value={name} onChange={(e) => { SetName(e.target.value) }} type="text" />
                                        </div>
                                        <div className="pr__form__group">
                                            <label htmlFor="mobile">Mobile <span className="required">*</span></label>
                                            <input className="pr-input" id="mobile" name="mobile" value={mobile} onChange={(e) => { SetMobile(e.target.value) }} type="text" />
                                        </div>
                                        <div className="pr__form__group">
                                            <label htmlFor="email">E-Mail <span className="required">*</span></label>
                                            <input className="pr-input" id="email" name="email" value={email} onChange={(e) => { SetEmail(e.target.value) }} type="text" />
                                        </div>
                                        <div className="pr__form__group">
                                            <label htmlFor="subject">Subject <span className="required">*</span></label>
                                            <input className="pr-input" id="subject" name="subject" value={subject} onChange={(e) => { SetSubject(e.target.value) }} type="text" />
                                        </div>
                                        <div className="pr__form__group">
                                            <label htmlFor="message">Your message <span className="required">*</span></label>
                                            <textarea className="pr-textarea" id="message" value={message} onChange={(e) => { SetMessage(e.target.value) }} name="message"></textarea>
                                        </div>
                                        <p className="pr__form__group uk-margin-large">
                                            {!loading && <button className="uk-button uk-button-large uk-button-primary" type="submit">Send message</button>}
                                            {loading && <div uk-spinner="ratio: 1"></div>}
                                        </p>
                                        {aletSuccessVisible && <div className="uk-alert-success uk-padding-small uk-margin" uk-alert>
                                            <p>Submitted Successfully.</p>
                                        </div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <WebFooter />
        </div>
    </>);
}