import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";

export default function TermUsesPage() {
    return (<>
        <MobileMenu isActive={"about"} />
        <div className="pr__wrapper" id="site-wrapper">
            <div className="pr__hero__wrap" style={{ backgroundImage: 'url(images/hero_01.png)' }} id="site-hero">
                <WebHeader isActive={"about"} />
                <section className="pr__hero uk-section" id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="hero-content uk-grid" data-uk-grid="">
                                <div className="uk-width-2-3@s">
                                    <hr className="line pr__hr__primary" />
                                    <h2 className="title uk-heading-hero">We're a digital agency.</h2>
                                    <a className="button uk-button uk-button-large uk-button-default uk-margin-top" href="!#"
                                        data-uk-scroll="">Getting Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pr__features uk-section uk-padding-remove-bottom" id="pr_features">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                data-uk-grid="">
                                <div className="item feature-box uk-active">
                                    <div className="inner">
                                        <i className="icon pr-chart-bars pr__icon__large"></i>
                                        <h3 className="title uk-h4">Future Vision.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">It is a long established fact that a reader will be
                                            distracted by the readable content of a page.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    <div className="inner">
                                        <i className="icon pr-rocket pr__icon__large"></i>
                                        <h3 className="title uk-h4">Product Design.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">There are many variations of passages of available, but the
                                            majority alteration in some form.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    <div className="inner">
                                        <i className="icon pr-pie-chart pr__icon__large"></i>
                                        <h3 className="title uk-h4">Inovative Solutions.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">The generated Lorem Ipsum is therefore always free from
                                            repetition, injected humour.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="pr__content" id="site-content">
                <hr className="pr__vr__section" />
                <section className="pr__about pr__section uk-section uk-section-large" id="pr__about">
                    <div className="section-outer">
                        <div className="section-heading">
                            <div className="outer">
                                <div className="uk-container">
                                    <div className="inner uk-grid" data-uk-grid="">
                                        <div className="left uk-width-expand">
                                            <hr className="line pr__hr__secondary" />
                                                <h2 className="title uk-h1">About.</h2>
                                                <span className="subtitle pr__heading__secondary">We're more than a digital agency</span>
                                        </div>
                                        <div className="right uk-width-3-5@s">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore corrupti
                                                temporibus fuga earum asperiores, alias excepturi sit. Impedit, fugit
                                                laudantium.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-inner">
                            <div className="uk-container">
                                <div className="gallery-boxes pr__grd__overlay uk-grid uk-grid-match uk-grid-medium"
                                    data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="item gallery-box big">
                                            <div className="outer">
                                                <div className="image pr__image__cover" data-src="images/about_01.jpg"
                                                    data-uk-img=""></div>
                                                <div className="inner">
                                                    <h3 className="title uk-h5">Teamwork</h3>
                                                    <p className="description">Committed and creative</p>
                                                    <a href="!#" className="link uk-position-cover"> </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right uk-width-1-3@m">
                                        <div className="item gallery-box small">
                                            <div className="outer">
                                                <div className="image pr__image__cover" data-src="images/about_03.jpg"
                                                    data-uk-img=""></div>
                                                <div className="inner">
                                                    <h3 className="title uk-h5">Philosophy</h3>
                                                    <p className="description">Trust pays off</p>
                                                    <a href="!#" className="link uk-position-cover" data-uk-toggle=""> </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item gallery-box small">
                                            <div className="outer">
                                                <div className="image pr__image__cover" data-src="images/about_02.jpg"
                                                    data-uk-img=""></div>
                                                <div className="inner">
                                                    <h3 className="title uk-h5">Office</h3>
                                                    <p className="description">Berlin, UK</p>
                                                    <a href="!#" className="link uk-position-cover" data-uk-toggle=""> </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-margin-large-top"
                                    data-uk-grid="">
                                    <div className="item about-box">
                                        <div className="outer">
                                            <div className="inner">
                                                <h3 className="title uk-h5">Who we are</h3>
                                                <p className="description">Bring to the table win-win survival strategies to
                                                    ensure proactive domination. At the end of the day, going forward will
                                                    have multiple touchpoints for offshoring.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item about-box">
                                        <div className="outer">
                                            <div className="inner">
                                                <h3 className="title uk-h5">Our philosophy</h3>
                                                <p className="description">A new normal that has evolved from generation X is
                                                    on the runway heading towards a streamlined cloud solution. User
                                                    generated content in real-time.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item about-box">
                                        <div className="outer">
                                            <div className="inner">
                                                <h3 className="title uk-h5">How we work</h3>
                                                <p className="description">Capitalize on low hanging fruit to identify a
                                                    ballpark value added activity to beta test. Override the digital divide
                                                    with additional clickthroughs from DevOps.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <WebFooter />
        </div>
    </>);

}