import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";

export default function FreshLeadsPage() {
    return (<>
        <MobileMenu isActive={"freshleads"} />
        <div className="pr__wrapper" id="site-wrapper">
            <div className="pr__hero__wrap pr__hero__wrap_why " id="site-hero">
                <WebHeader isActive={"freshleads"} />
                <section className="pr__hero uk-section " id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="hero-content uk-grid" data-uk-grid="">
                                <div className="uk-width-2-3@s">
                                    <hr className="line pr__hr__primary" />
                                    <h2 className="title uk-heading-hero">Why<br /> FreshLeads.</h2>
                                    <a className="button uk-button uk-button-large uk-button-default uk-margin-top" href="!#"
                                        data-uk-scroll="site-content">Getting Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pr__hero_image_section " id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="image pr__image__cover" style={{ backgroundSize: 'contain', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/why.png" data-uk-img=""></div>
                        </div>
                    </div>
                </section>
                <section className="pr__features uk-section  uk-section-large uk-padding-remove-bottom" id="pr_features">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                data-uk-grid="">
                                <div className="item  ">
                                    <div>&nbsp;</div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="pr__content" id="site-content">
                <hr className="pr__vr__section" />
                <section className="pr__about pr__section uk-section">
                    <div className="section-outer">
                        <div className="section-heading" style={{ paddingBottom: 90 }}>
                            <div className="outer">
                                <div className="uk-container">
                                    <div className="inner uk-grid" data-uk-grid="">
                                        <div className="left uk-width-expand">
                                            <hr className="line pr__hr__secondary" />
                                            <h2 className="title uk-h1">How we make a difference</h2>
                                            <span className="subtitle pr__heading__secondary">We committed to deliver the growth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-inner" id="pr__about">
                            <div className="uk-container">
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/define_the_goals.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Define The Goals</h2>
                                        <p className="description">FreshLeads team talks to clients to identify and define the goals. The goals help us to craft customized digital marketing strategies.</p>
                                        <p className="description">If the goal is to increase brand awareness, we pay more attention to reaching new audiences via social media channels.</p>
                                        <p className="description">Alternatively, the goal is to increase sales on a specific product, we will focus mostly on SEO and optimizing content to get potential buyers on the website in the first place.</p>
                                        <p className="description"> Additionally, if sales is the goal, we test out PPC campaigns to drive traffic through paid ads.</p>

                                    </div>
                                </div>
                                
                                <div className=" inner uk-grid " data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/identify_the_target_audience.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Identify The Target Audience</h2>
                                        <p className="description">FreshLeads team works closely with the clients to understand their business and the target audience with demographic information
                                            considering the facts of channels or goals for a specific product or campaign.</p>
                                    </div>

                                </div>
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/establish_a_budget_for_each_digital_channel.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Establish A Budget For Each Digital Channel</h2>
                                        <p className="description">We work with the budget that the client determines and it really depends on what elements of digital marketing to add to the strategy.</p>
                                    </div>
                                </div>
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className=" left  uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/paid_and_free_digital_strategies.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right  uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Strike a good balance between paid and free digital strategies</h2>
                                        <p className="description">A digital marketing strategy requires both paid and free aspects to truly be effective.</p>
                                        <p className="description">At FreshLeads, we focus on creating quality online content to attract and convert,
                                            then clients are likely to see strong results within the first six months despite minimal ad spend.</p>
                                        <p className="description">However, if paid advertising is part of our digital strategy, then the results might come even quicker and we strike a good balance with both.</p>
                                    </div>

                                </div>
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/create_engaging_content.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Create Engaging Content</h2>
                                        <p className="description">At FreshLeads, our team creates interesting and engaging content keeping in mind the target audience because the point of marketing content is to increase brand awareness and improve lead generation.</p>
                                    </div>
                                </div>
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className=" left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/conduct_keyword_research.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right  uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Conduct Keyword Research</h2>
                                        <p className="description">We focus on keyword research for optimizing the website and content for SEO and ensuring people can find the business through search engines.</p>
                                        <p className="description">Additionally, social media keyword research can be helpful for marketing the products or services on various social channels.</p>
                                    </div>

                                </div>
                                <div className="inner uk-grid" data-uk-grid="">
                                    <div className="left uk-width-expand">
                                        <div className="image pr__image__cover" style={{ backgroundSize: '250px', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/iterate_based_on_the_analytics_we_measure.png" data-uk-img=""></div>
                                    </div>
                                    <div className="right uk-width-3-5@s" style={{ margin: 'auto 0' }}>
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h4">Iterate based on the analytics we measure</h2>
                                        <p className="description">We work with incredibly flexible opportunities for continuous growth and measure ROI on spendings and optimize the digital marketing strategy.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <WebFooter />
        </div>
    </>);

}