import { Link } from "react-router-dom";

export default function WebFooter() {

    return (
        <footer className="pr__footer" id="site-footer">
            <div className="pr__footer__top uk-section uk-section-large">
                <div className="section-outer">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="columns uk-grid" data-uk-grid="">
                                <div className="pr__cta column">
                                    <div className="inner">
                                        <h2 className="title uk-h1">Let's Talk?</h2>
                                    </div>
                                </div>
                                <div className="pr__cta column">
                                    <div className="inner">
                                        <Link to="/contact" className="button uk-button uk-button-large uk-button-default">Make an enquiry</Link>
                                    </div>
                                </div>
                                <div className="pr__social column">
                                    <div className="inner">
                                        <a href="https://www.facebook.com/profile.php?id=100078955665683" rel={'noreferrer'} target={'_blank'} className="icon pr-logo-facebook"> </a>
                                        <a href="https://twitter.com/FreshleadsT" target={'_blank'} rel={'noreferrer'} className="icon pr-logo-twitter"> </a>
                                        <a href="https://www.instagram.com/freshleads/" target={'_blank'} rel={'noreferrer'} className="icon pr-logo-instagram"> </a>
                                        {/* <a href="!#"  target={'_blank'} className="icon pr-logo-linkedin"> </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pr__footer__center uk-section uk-section-small">
                <div className="uk-container">
                    <ul>
                        <li><a href="!#">Kendall Park, NJ USA.<span className="phone">+1 732 470 1999</span></a></li>
                        <li><a href="!#">Nagpur, India.<span className="phone">+91 97649 01155</span></a></li>
                    </ul>
                </div>
            </div>
            <div className="pr__footer__bottom uk-section">
                <div className="section-outer">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="columns uk-grid" data-uk-grid="">
                                <div className="pr__links column">
                                    <div className="inner">
                                        <Link to="">Terms of use</Link>
                                        <Link to="" >Privacy Policy</Link>
                                        <Link to="" >Cookie Policy</Link>
                                    </div>
                                </div>
                                <div className="pr__copyrights column">
                                    <div className="inner">
                                        <p>2022 © FreshLeads, All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );

}