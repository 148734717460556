
import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function HomePage() {

    return (
        <>
            <MobileMenu isActive={"home"} />
            <div className="pr__wrapper" id="site-wrapper">
                <div className="pr__hero__wrap" id="site-hero">
                    <WebHeader isActive={"home"} />

                    <section className="pr__hero uk-section" id="pr__hero">
                        <div className="uk-container">
                            <div className="section-inner">
                                <div className="hero-content uk-grid" data-uk-grid="">
                                    <div className="uk-width-2-3@s">
                                        <hr className="line pr__hr__primary" />
                                        <h2 className="title uk-heading-hero">We're a digital agency.</h2>
                                        <a className="button uk-button uk-button-large uk-button-default uk-margin-top" href="#site-content"
                                            data-uk-scroll="site-content">Getting Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pr__hero_image_section" id="pr__hero">
                        <div className="uk-container">
                            <div className="section-inner">
                                <div className="image pr__image__cover" style={{ backgroundSize: 'contain', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/hero_01.png" data-uk-img=""></div>
                            </div>
                        </div>
                    </section>
                    <section className="pr__features uk-section uk-padding-remove-bottom" id="pr_features">
                        <div className="uk-container">
                            <div className="section-inner">
                                <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                    data-uk-grid="">
                                    <div className="item feature-box uk-active">
                                        <div className="inner">
                                            <i className="icon pr-chart-bars pr__icon__large"></i>
                                            <h3 className="title uk-h4">Future Vision.</h3>
                                            <hr className="line pr__hr__secondary" />
                                            <p className="description">FreshLeads committed to deliver the customer engagement with brands in digital way
                                                with the use of gadgets/devices and new emerging platforms.</p>
                                            <p className="description">As engagement changes, so FreshLeads will adapt to serve the better to live upto expectations.</p>

                                        </div>
                                    </div>
                                    <div className="item feature-box">
                                        <div className="inner">
                                            <i className="icon pr-rocket pr__icon__large"></i>
                                            <h3 className="title uk-h4">Digital Product Design</h3>
                                            <hr className="line pr__hr__secondary" />
                                            <p className="description">Digital Product Design experts at FreshLeads, understand the design process
                                                of identifying design opportunities within customer niche market, creating new product ideas,
                                                conducting market research and receiving consumer feedback considering user experience.</p>
                                        </div>
                                    </div>
                                    <div className="item feature-box">
                                        <div className="inner">
                                            <i className="icon pr-pie-chart pr__icon__large"></i>
                                            <h3 className="title uk-h4">Inovative Solutions.</h3>
                                            <hr className="line pr__hr__secondary" />
                                            <p className="description">FreshLeads crafts innovative business solutions which suits to the current
                                                and future problem statements considering users requirements, desires or priorities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="pr__content" id="site-content">
                    <hr className="pr__vr__section" />


                    <section className="pr__services pr__section uk-section uk-section-large" id="pr__services">
                        <div className="section-outer">
                            <div className="section-heading">
                                <div className="uk-container">
                                    <div className="inner">
                                        <div className="left">
                                            <hr className="line pr__hr__secondary" />
                                            <h2 className="title uk-h1">Services.</h2>
                                            <span className="subtitle pr__heading__secondary">We work with you, Not for you</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-inner">
                                <div className="uk-container">
                                    <div className="items-listing services-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@l uk-child-width-1-2@s" data-uk-grid="">
                                        <div className="item service-box style-two uk-active">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-strategy"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Marketing Strategy</h5>
                                                    <p className="description">Digital presence of business is changing the way brand attracts leads, engage prospects, create conversions.</p>
                                                    <p className="description">A specialized marketing strategies with the real time data analytics can help businesses in lead management and customer retention.</p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-envelope"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Marketing Campaigns</h5>
                                                    <p className="description">We drive digital campaigns with Social media ads, Google Ads and Email automations,
                                                        our team of experts are here to develope different marketing campaigns that attracts visitors and convert them into loyal customers.
                                                    </p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-document"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Email Marketing</h5>

                                                    <p className="description">Our email marketing campaigns are targeted for potential customers to convert them into new opportunities/aquisitions and existing customer for long term retentions.</p>
                                                    <p className="description">Our email marketing strategies includes promotional activities, product sales/services and events for brand building with email campaigns.</p>

                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-presentation"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Social Media Marketing</h5>
                                                    <p className="description">
                                                        Social media team is here to talk to you on social media marketing strategies to stand out in competition.</p>
                                                    <p className="description">
                                                        Our strategies get your brand noticed, shared, followed and liked, no matter the social platforms, we have the specialized team to meet your all the social media marketing needs and objectives.
                                                    </p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-linegraph"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Marketing Analytics</h5>
                                                    <p className="description">We offer Marketing Analytics reporting dashboards to analyze and monitor you Digital ads spendings and ROI across all digital channels to make informed descisions in future.</p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-newspaper"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Search Engine Optimization (SEO)</h5>
                                                    <p className="description">
                                                        Digital presence is the key step to expand business online, now business requires to take a next step to make sure to get investment optimized and get the search results you deserve.</p>
                                                    <p className="description">
                                                        Our SEO team is committed to deliver results, no matter the type of business/services you provide.
                                                    </p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-pictures"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Content Marketing</h5>
                                                    <p className="description">
                                                        Curated social media content is the tool to differentiate between a good brand and a great one.</p>
                                                    <p className="description">
                                                        Creating social media contents which can spin on the verifiable leads is extremely important. We pride ourselves on our teams ability to curate and create highly-engaging and effective social media contents.
                                                    </p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-target"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Lead Generation</h5>
                                                    <p className="description">
                                                        We specilaize in fresh lead generations using our unique marketing strategies. From optimized landing pages to Call to Actions on social media platforms, we can configure and optimize a successful lead generation strategy.
                                                    </p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="item service-box style-two">
                                            <div className="inner">
                                                <i className="overlay-icon icon pr-line-phone"></i>
                                                <div className="content">
                                                    <h5 className="title uk-h5">Pay Per Click (PPC)</h5>
                                                    <p className="description">Our cost-effective PPC strategy allows the business to target potential customers based on keyword search, demographic info and more.</p>
                                                    <p className="description"> We are committed for driving website traffic and help the business to take advanatge of PPC perk.</p>
                                                    <i className="more icon pr-arrow-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <hr className="pr__vr__section" />
                    <section className="pr__works pr__section section-slider uk-section uk-section-large" id="pr__works">
                        <div className="section-outer">
                            <div className="section-heading">
                                <div className="uk-container">
                                    <div className="inner">
                                        <div className="left">
                                            <hr className="line pr__hr__secondary" />
                                            <h2 className="title uk-h1">What Clients Say.</h2>
                                            <span className="subtitle pr__heading__secondary">From all over the world</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="section-inner">
                                <div className="uk-container">
                                    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showArrows={false} showStatus={false} swipeable={true}>
                                        <div className="item work-box">
                                            <div>
                                                <div className="uk-flex uk-flex-middle uk-grid uk-grid-large" data-uk-grid="">
                                                    <div className="right uk-width-expand">
                                                        <div className="section-heading" style={{ marginBottom: 30 }}>
                                                            <div className="inner">
                                                                <div className="left">
                                                                    <hr className="line pr__hr__secondary" />
                                                                    <h2 className="title uk-h1" style={{ fontSize: 25 }}>Ravi Ramteke</h2>
                                                                    <span className="subtitle pr__heading__secondary" >Director</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="section-inner">
                                                            <div className="item client-box style-one">
                                                                <div className="outer">
                                                                    <div className="inner">
                                                                        <p className="description">“We were truly impressed and satisfied with the work.
                                                                            FreshLeads team worked very closely with us crafting the marketing strategies striking the balance of the right mix to meet our digital marketing goals and objectives within the budget and time."</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="left uk-width-1-5@m uk-flex-first">
                                                        <div className="item client-box style-one client-image">
                                                            <div className="image pr__image__cover pr__ratio__portrait" data-src="images/auriga.png"
                                                                data-uk-img=""></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item work-box">
                                            <div>
                                                <div className="uk-flex uk-flex-middle uk-grid uk-grid-large" data-uk-grid="">
                                                    <div className="right uk-width-expand">
                                                        <div className="section-heading" style={{ marginBottom: 30 }}>
                                                            <div className="inner">
                                                                <div className="left">
                                                                    <hr className="line pr__hr__secondary" />
                                                                    <h2 className="title uk-h6" style={{ fontSize: 25 }}>Dhananjay Urkude</h2>
                                                                    <span className="subtitle pr__heading__secondary" >Managing Partner</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="section-inner">
                                                            <div className="item client-box style-one">
                                                                <div className="outer">
                                                                    <div className="inner">
                                                                        <p className="description">“To target the overseas market, FreshLeads team helped us to build the trust and Tropica brand.
                                                                            They understand our business objectives and strive with innovative business solutions to meet our marketing objectives.
                                                                            The results are awesome and continue this engagement to see future growth."</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="left uk-width-1-5@m uk-flex-first">
                                                        <div className="item client-box style-one client-image">
                                                            <div className="image pr__image__cover pr__ratio__portrait" data-src="images/tropica.png"
                                                                data-uk-img=""></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item work-box">
                                            <div>
                                                <div className="uk-flex uk-flex-middle uk-grid uk-grid-large" data-uk-grid="">
                                                    <div className="right uk-width-expand">
                                                        <div className="section-heading" style={{ marginBottom: 30 }}>
                                                            <div className="inner">
                                                                <div className="left">
                                                                    <hr className="line pr__hr__secondary" />
                                                                    <h2 className="title uk-h1" style={{ fontSize: 25 }}>Amol Aher</h2>
                                                                    <span className="subtitle pr__heading__secondary" style={{ marginBottom: 30 }}>Director</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="section-inner">
                                                            <div className="item client-box style-one">
                                                                <div className="outer">
                                                                    <div className="inner">
                                                                        <p className="description">"FreshLeads team helped immensely to make us stand in the crowded market of IT Training and Consulting business.
                                                                            They not only worked closely with us to understand the business goals but went extra miles to get satisfying reviews from the student / professional community with their out of box strategies and that worked very well."</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="left uk-width-1-5@m uk-flex-first">
                                                        <div className="item client-box style-one client-image">
                                                            <div className="image pr__image__cover pr__ratio__portrait" data-src="images/kasnet.png"
                                                                data-uk-img=""></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Carousel>
                                </div>
                            </div>


                        </div>
                    </section>
                </div>
                <WebFooter />
            </div>

        </>
    );

}
