import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";

export default function ServicesPage() {
    return (<>
        <MobileMenu isActive={"services"} />
        <div className="pr__wrapper" id="site-wrapper">
            <div className="pr__hero__wrap" style={{ backgroundImage: 'url(images/hero_01.png)' }} id="site-hero">
                <WebHeader isActive={"services"} />
                <section className="pr__hero uk-section" id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="hero-content uk-grid" data-uk-grid="">
                                <div className="uk-width-2-3@s">
                                    <hr className="line pr__hr__primary" />
                                    <h2 className="title uk-heading-hero">We're a digital agency.</h2>
                                    <a className="button uk-button uk-button-large uk-button-default uk-margin-top" href="!#"
                                        data-uk-scroll="">Getting Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pr__features uk-section uk-padding-remove-bottom" id="pr_features">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                data-uk-grid="">
                                <div className="item feature-box uk-active">
                                    <div className="inner">
                                        <i className="icon pr-chart-bars pr__icon__large"></i>
                                        <h3 className="title uk-h4">Future Vision.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">It is a long established fact that a reader will be
                                            distracted by the readable content of a page.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    <div className="inner">
                                        <i className="icon pr-rocket pr__icon__large"></i>
                                        <h3 className="title uk-h4">Product Design.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">There are many variations of passages of available, but the
                                            majority alteration in some form.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    <div className="inner">
                                        <i className="icon pr-pie-chart pr__icon__large"></i>
                                        <h3 className="title uk-h4">Inovative Solutions.</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">The generated Lorem Ipsum is therefore always free from
                                            repetition, injected humour.</p>
                                        <a href="!#" className="link uk-position-cover"> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="pr__content" id="site-content">
                <hr className="pr__vr__section" />
                <section className="pr__services pr__section uk-section uk-section-large" id="pr__services">
                    <div className="section-outer">
                        <div className="section-heading">
                            <div className="uk-container">
                                <div className="inner">
                                    <div className="left">
                                        <hr className="line pr__hr__secondary" />
                                        <h2 className="title uk-h1">Services.</h2>
                                        <span className="subtitle pr__heading__secondary">We work with you, Not for you</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-inner">
                            <div className="uk-container">
                                <div className="items-listing services-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
                                    data-uk-grid="">
                                    <div className="item service-box style-one uk-active">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-strategy"></i>
                                            <h5 className="title uk-h5">Digital Strategy</h5>
                                            <i className="icon pr-arrow-right"></i>
                                           
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-browser"></i>
                                            <h5 className="title uk-h5">UX Design</h5>
                                            <i className="icon pr-arrow-right"></i>
                                            
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-presentation"></i>
                                            <h5 className="title uk-h5">Product Design</h5>
                                            <i className="icon pr-arrow-right"></i>
                                           
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-clipboard"></i>
                                            <h5 className="title uk-h5">Content Strategy</h5>
                                            <i className="icon pr-arrow-right"></i>
                                            
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-tools"></i>
                                            <h5 className="title uk-h5">Design & Concept</h5>
                                            <i className="icon pr-arrow-right"></i>
                                           
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-chat"></i>
                                            <h5 className="title uk-h5">Social Media</h5>
                                            <i className="icon pr-arrow-right"></i>
                                           
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-target"></i>
                                            <h5 className="title uk-h5">Media Planing</h5>
                                            <i className="icon pr-arrow-right"></i>
                                            
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-lightbulb"></i>
                                            <h5 className="title uk-h5">Brand Identity</h5>
                                            <i className="icon pr-arrow-right"></i>
                                            
                                        </div>
                                    </div>
                                    <div className="item service-box style-one">
                                        <div className="inner">
                                            <i className="overlay-icon icon pr-line-search"></i>
                                            <h5 className="title uk-h5">SEO Optimization</h5>
                                            <i className="icon pr-arrow-right"></i>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <WebFooter />
        </div>
    </>);

}