
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function WebHeader(props: { isActive: string; }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <header className="pr__header" data-uk-sticky="top: 100vh; animation: uk-animation-slide-top;">
            <div className="uk-container">
                <div className="inner">
                    <div className="logo">
                        <Link to="/">
                            <img src={'images/logo.png'} style={{ width: '64%' }} alt="logo" />
                        </Link>
                    </div>
                    <div className="navbar pr-font-second">
                        <nav className="menu" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                            <ul>
                                <li className={props.isActive === 'home' ? 'uk-active' : ''}><Link to="/">Home</Link></li>
                                {/* <li className={props.isActive === 'services' ? 'uk-active' : ''}><Link to="/services">Our Services</Link></li> */}
                                <li className={props.isActive === 'about' ? 'uk-active' : ''}><Link to="/about">About us</Link></li>
                                <li className={props.isActive === 'freshleads' ? 'uk-active' : ''}><Link to="/why-freshleads">Why FreshLeads</Link></li>
                                <li className={props.isActive === 'contact' ? 'uk-active' : ''}><Link to="/contact">Contact us</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="navbar-tigger" data-uk-toggle="target: #navbar-mobile">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    );

}