import MobileMenu from "../component/MobileMenu";
import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";

export default function AboutPage() {
    return (<>
        <MobileMenu isActive={"about"} />
        <div className="pr__wrapper" id="site-wrapper">
            <div className="pr__hero__wrap pr__hero__wrap_about"  id="site-hero">
                <WebHeader isActive={"about"} />
                <section className="pr__hero uk-section" id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="hero-content uk-grid" data-uk-grid="">
                                <div className="uk-width-2-3@s">
                                    <hr className="line pr__hr__primary" />
                                    <h2 className="title uk-heading-hero">About <br />FreshLeads.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pr__hero_image_section" id="pr__hero">
                    <div className="uk-container">
                        <div className="section-inner">
                            <div className="image pr__image__cover" style={{ backgroundSize: 'contain', backgroundColor: '#fad7d700', filter: 'grayscale(0%)' }} data-src="images/updated_about us_.png" data-uk-img=""></div>
                        </div>
                    </div>
                </section>

                <section className="pr__features uk-section uk-padding-remove-bottom " id="pr_features">
                    <div className="uk-container">
                        <div className="section-inner">

                            <div className="items-listing features-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m"
                                data-uk-grid="">
                                <div className="item feature-box uk-active">
                                    <div className="inner">
                                        <img src="images/who we are_w.png" alt="how_we_work_w" style={{ width: 100, height: 100 }} />

                                        <h3 className="title uk-h4">Who we are</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">We are a team of digital marketers committed to deliver the growth with brand promotion, clean design, well-curated content and integrated digital marketing solutions.</p>
                                       
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    
                                    <div className="inner">
                                        {/* <img src="images/how_we_work_w.png" alt="how_we_work_w" style={{ width: 100, height: 100 }} /> */}
                                        <div className="our_philosophy_image"  style={{ width: 100, height: 100 }}>&nbsp;</div>
                                        <h3 className="title uk-h4">Our philosophy</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">We believe in a client first approach integrating data-driven innovative methods to reach the target audience striking a balance between paid and free digital strategies.</p>
                                        
                                    </div>
                                </div>
                                <div className="item feature-box">
                                    <div className="inner">
                                    <div className="our_how_work_image"  style={{ width: 100, height: 100 }}>&nbsp;</div>
                                        <h3 className="title uk-h4">How we work</h3>
                                        <hr className="line pr__hr__secondary" />
                                        <p className="description">We work with you, not for you to craft the digital marketing strategies that suit your business goals and customers to exceed expectations and deliver.</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="pr__content" id="site-content">
                <hr className="pr__vr__section" />

            </div>
            <section className="pr__features uk-section uk-padding-remove-bottom uk-margin-large-top" id="pr_features">
                <div className="uk-container">
                    <div className="section-inner">

                        <div className="uk-column-1-2@s uk-column-divider ">
                            <div className=" uk-height-large abtitle">
                                <h2 className="title uk-tile uk-padding-large center"><span className="uk-primary">Digital</span> Transformation Solutions</h2>
                            </div>

                            <div  style={{ textAlign: 'justify',minHeight:450 }}>
                                <p>We started our journey with FreshLeads Technologies in Jan 2022, based in NJ USA and Nagpur, India for the complete digital transformations of small, medium and startups businesses closely working with you, understanding your business needs through content creations, storytelling, brand strategies to maximize the reach to targeted customers using digital platforms and providing innovative digital solutions as digital marketing company, co-founded with Reena Khalasa and Geeta Dewalkar.</p>
                                <p>We are a team of creative, innovative, problem solvers and strategic-thinking digital marketing experts, who believe in empowering the companies to expand their businesses and maximize reach with our unique approaches and make informed decisions with ROI's on spendings using integrated Marketing Dashboards.</p>
                                <p>The marketing strategies we create, the ad campaign we curate, and customer experience we build, is based on data insights to drive the promising results for your company products and services delivering the business values.</p>
                                <p>Let's talk and work together to make a difference in your digital journey !!!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <br />
            <WebFooter />
        </div>
    </>);

}