import React from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import FreshLeadsPage from './pages/FreshLeadsPage';
import TermUsesPage from './pages/TermUsesPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiesPolicyPage from './pages/CookiesPolicyPage';


function App() {
  return (
    <div className="App">
      <Routes >
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="why-freshleads" element={<FreshLeadsPage />} />   
        <Route path="term-uses" element={<TermUsesPage />} />   
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />   
        <Route path="cookies-uses" element={<CookiesPolicyPage />} />       
      </Routes>
    </div>
  );
}


export default App;
