import { Link } from 'react-router-dom';
export default function MobileMenu(props: { isActive: string; }) {
    return (
        <div className="pr__mobile__nav" id="navbar-mobile" data-uk-offcanvas="overlay: true; flip: true; mode: none">
            <div className="uk-offcanvas-bar">
                <button className="uk-offcanvas-close" type="button" data-uk-close="ratio: 2;"></button>
                <nav className="menu" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul data-uk-scrollspy="target: > li; cls:uk-animation-slide-right; delay: 100; repeat: true;">
                        <li className={props.isActive === 'home' ? 'uk-active' : ''}><Link to="/">Home</Link></li>
                        <li className={props.isActive === 'about' ? 'uk-active' : ''}><Link to="/about">About us</Link></li>
                        <li className={props.isActive === 'freshleads' ? 'uk-active' : ''}><Link to="/why-freshleads">Why FreshLeads</Link></li>
                        {/* <li className={props.isActive === 'services' ? 'uk-active' : ''}><Link to="/services">Our Services</Link></li> */}
                        <li className={props.isActive === 'contact' ? 'uk-active' : ''}><Link to="/contact">Contact us</Link></li>
                        
                    </ul>
                </nav>
            </div>
        </div>
    );

}